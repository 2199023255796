.add-button {
  display: flex;
  justify-content: center;
  padding: 10px;
  border: 1px dashed #FFFAEF;
  background-color: #424242;
  border-radius: 6px;
  cursor: pointer;
}

.add-button > button {
  color: #FFFAEF !important;
}

.add-button:hover {
  background-color: #D8756B;
  border: 1px dashed #D8756B;
}

.event-list {
  border: 1px solid #0f0f0f50;
  border-radius: 6px;
  padding: 10px;
  min-height: 160px;
  margin-bottom: 12px;
  background-color: #424242;
  padding-right: 70px !important;
}

.cd-toolbar, .ck-toolbar_grouping {
  border-radius: 4px 4px 0 0 !important;
}

.ck-editor__editable_inline {
  border-radius: 0 0 4px 4px !important;
}

.livestream-vimeo-chat > iframe {
  min-height: 550px !important;
  box-shadow: 1px 1px 10px #00000050;
}

.livestream-vimeo-video {
  box-shadow: 1px 1px 10px #00000050;
}

.livestream-footer {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

body {
  margin-bottom: 50px;
}

html {
  min-height: 100%;
  position: relative;
  background-color: #212121;
  color: #FFFAEF;
}

.admin-text, .admin-button {
  font-family: 'Poppins', 'Roboto', sans-serif !important;
}

.admin-button {
  text-transform: none !important;
}

.admin-text-h2 {
  font-family: 'Poppins', 'Roboto', sans-serif !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.admin-text-h3 {
  font-family: 'Poppins', 'Roboto', sans-serif !important;
  color: #D8756B;
}

.admin-text-h2 > h2 {
  font-family: 'Poppins', 'Roboto', sans-serif !important;
  font-weight: 500 !important;
}

.admin-title {
  font-family: 'Dela Gothic One', 'Roboto', sans-serif !important;
}

a {
  color: inherit;
}